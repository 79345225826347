@import url(https://fonts.googleapis.com/css2?family=Lora&family=Montserrat&display=swap);
/* Style the accordion section */
.Accordion_accordion__section__36R8q {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.Accordion_accordion__1pwVk {
  background-color: #fff;
  color: #202020;
  cursor: pointer;
  padding: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

.Accordion_accordion__header__2x0Xw {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

/* Style the accordion content title */
.Accordion_accordion__title__8M5lo {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
}

.Accordion_accordion__subtitle__8lZdg {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
}

/* Style the accordion chevron icon */
.Accordion_accordion__icon__1e9O8 {
  margin-left: auto;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.Accordion_rotate__1xOry {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.Accordion_accordion__content__1hbPt {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-items: center;
          align-items: center;
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
  margin: 1.5rem;
}

.Accordion_accordion__image__2J18T {
  width: 15rem;
  height: 15rem;
}

/* Style the accordion content text */
.Accordion_accordion__text__UFbDs {
  font-family: "Lora", serif;
  font-weight: 400;
  font-size: 1rem;
  padding: 2rem;
}

@media screen and (min-width: 760px) {
  .Accordion_accordion__1pwVk {
    padding: 18px;
  }
  .Accordion_accordion__header__2x0Xw {
    -webkit-flex-flow: row;
            flex-flow: row;
  }
  .Accordion_accordion__title__8M5lo {
    margin-right: 1rem;
  }
  .Accordion_accordion__1pwVk:hover,
  .Accordion_active__1KEra {
    background-color: #b6bfc2;
  }
  .Accordion_accordion__title__8M5lo {
    font-size: 2rem;
  }
  .Accordion_accordion__subtitle__8lZdg {
    font-size: 2rem;
  }
  .Accordion_accordion__content__1hbPt {
    -webkit-flex-flow: row;
            flex-flow: row;
  }
}

