@import url("https://fonts.googleapis.com/css2?family=Lora&family=Montserrat&display=swap");

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #fff;
  color: #202020;
  cursor: pointer;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

.accordion__header {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

/* Style the accordion content title */
.accordion__title {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
}

.accordion__subtitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
  margin: 1.5rem;
}

.accordion__image {
  width: 15rem;
  height: 15rem;
}

/* Style the accordion content text */
.accordion__text {
  font-family: "Lora", serif;
  font-weight: 400;
  font-size: 1rem;
  padding: 2rem;
}

@media screen and (min-width: 760px) {
  .accordion {
    padding: 18px;
  }
  .accordion__header {
    flex-flow: row;
  }
  .accordion__title {
    margin-right: 1rem;
  }
  .accordion:hover,
  .active {
    background-color: #b6bfc2;
  }
  .accordion__title {
    font-size: 2rem;
  }
  .accordion__subtitle {
    font-size: 2rem;
  }
  .accordion__content {
    flex-flow: row;
  }
}
